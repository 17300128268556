export const GET_FEATURE_SERVICES_REQUEST = 'get::featureServices::request'
export const GET_FEATURE_SERVICES_SUCCESS = 'get::featureServices::success'
export const GET_FEATURE_SERVICES_FAIL = 'get::featureServices::fail'

export const GET_FEATURE_BLOGS_REQUEST = 'get::featureBlogs::request'
export const GET_FEATURE_BLOGS_SUCCESS = 'get::featureBlogs::success'
export const GET_FEATURE_BLOGS_FAIL = 'get::featureBlogs::fail'

export const GET_FEATURE_PACKAGES_REQUEST = 'get::featurePackages::request'
export const GET_FEATURE_PACKAGES_SUCCESS = 'get::featurePackages::success'
export const GET_FEATURE_PACKAGES_FAIL = 'get::featurePackages::fail'

export const GET_FEATURE_PRODUCTS_REQUEST = 'get::featureProducts::request'
export const GET_FEATURE_PRODUCTS_SUCCESS = 'get::featureProducts::success'
export const GET_FEATURE_PRODUCTS_FAIL = 'get::featureProducts::fail'

export const GET_FEATURE_TECH_DOC_REQUEST = 'get::featureTechDoc::request'
export const GET_FEATURE_TECH_DOC_SUCCESS = 'get::featureTechDoc::success'
export const GET_FEATURE_TECH_DOC_FAIL = 'get::featureTechDoc::fail'

export const GET_FEATURE_FAQS_REQUEST = 'get::featureFaqs::request'
export const GET_FEATURE_FAQS_SUCCESS = 'get::featureFaqs::success'
export const GET_FEATURE_FAQS_FAIL = 'get::featureFaqs::fail'

export const GET_FEATURE_ALL_REQUEST = 'get::allArticles::request'
export const GET_FEATURE_ALL_SUCCESS = 'get::allArticles::success'
export const GET_FEATURE_ALL_FAIL = 'get::allArticles::fail'

export const GET_RELATED_CATEGORY_REQUEST = 'get::relatedCategory::request'
export const GET_RELATED_CATEGORY_SUCCESS = 'get::relatedCategory::success'
export const GET_RELATED_CATEGORY_FAIL = 'get::relatedCategory::fail'

export const FEATURE_ARTICLES_RESET = 'get::featureArticles::reset'
