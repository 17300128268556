import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {reviewer_1} from '../testimonials/content/testimonials-content'

const vehicleTypesTiles = [
    {
        title: 'Modern cars',
        image: 'modern-cars.jpg',
        id: 1,
        linkURL: '/cars/modern-hire-car',
        icon: <ArrowForwardIcon/>
    },
    {
        title: 'Vintage cars',
        image: 'vintage-cars.jpg',
        id: 2,
        linkURL: '/cars/vintage-hire-car',
        icon: <ArrowForwardIcon/>
    },
    {
        title: 'Sport cars',
        image: 'sport-cars.jpg',
        id: 5,
        linkURL: '/cars/sport-hire-car',
        icon: <ArrowForwardIcon/>
    },
    {
        title: 'Limousines',
        image: 'limos.jpg',
        id: 6,
        linkURL: '/cars/limousine-hire',
        icon: <ArrowForwardIcon/>
    },
    {
        title: 'Convertibles',
        image: 'convertables.jpg',
        id: 7,
        linkURL: '/cars/convertible-car-hire',
        icon: <ArrowForwardIcon/>
    },
    {
        title: 'Special offers',
        image: 'special-offers.jpg',
        id: 8,
        linkURL: '/cars/sydney-discount-hire-car-service',
        icon: <ArrowForwardIcon/>
    }
    //todo - maybe after
    // {
    //     title:'Bikes',
    //     image:'bikes.jpg',
    //     id:3,
    //     linkURL:'/contact',
    //     icon : <ArrowForwardIcon />
    // },
    // {
    //     title:'Line ups',
    //     image:'line-ups.jpg',
    //     id:4,
    //     linkURL:'/contact',
    //     icon : <ArrowForwardIcon />
    // },
]

const popularChoicesTiles = [
    {
        title: 'Maserati Grancabrio Sport',
        image: 'sport-cars.jpg',
        id: 1,
        linkURL: '/cars/modern-hire-car/maserati-grancabrio-sport',
        icon: null
    },
    {
        title: 'Rolls Royce Phantom',
        image: 'modern-cars.jpg',
        id: 2,
        linkURL: '/cars/limousine-hire/rolls-royce-phantom',
        icon: null
    },
    {
        title: 'Stretch Chrysler 300C',
        image: 'limos.jpg',
        id: 3,
        linkURL: '/cars/modern-hire-car/chrysler-300c-stretch',
        icon: null
    },
    {
        title: 'Jaguar 1940 White Sedan',
        image: 'vintage-cars.jpg',
        id: 5,
        linkURL: '/cars/vintage-hire-car/1940-white-jaguar-sedan-daisy',
        icon: null
    },
]

const commonContent = {
    reviewText: "",
    buttonRoute: '',
    button: "",
    ...reviewer_1
}

const testimonialSlides = [
    {
        title: "What our clients say",
        description:
            "Drivers arrived early, followed our schedule and very polite throughout the day, 10/10 stars and 100% recommend M1 Limousines.",

        image: "assets/img/components/reactive-slider/rolls-royce-phantom.jpg",
        reviewUserDetails: "Alex M",
        ...commonContent,
    },
    {
        title: "What our clients say",
        description:
            "Excellent service, immaculate wedding cars and respectful drivers is what we received when we booked our cars with M1 Limousines. Have recommended them to all friends and family.",
        image: "assets/img/components/reactive-slider/sport-car-hire.jpg",
        reviewUserDetails: "Amanda Scully",
        ...commonContent,
    },

    {
        title: "What our clients say",
        description:
            "Best service ever\n" +
            "Hired M1 limousines for my school Formal\n" +
            "Amazing service, amazing staff, Driver arrived early, great customer service, smooth ride and lots of patience.\n" +
            "Would highly recommend them !",
        image: "assets/img/components/reactive-slider/vintage-car-hire.jpg",
        reviewUserDetails: "kapiolani paletua",
        ...commonContent,
    },
    {
        title: "What our clients say",
        description:
            "We used M1 Limousines for our formal this year. They were absolutely amazing in every way, we were looked after and treated like princesses !! Would highly recommend them !! Thank you again.",
        image: "assets/img/components/reactive-slider/sport-car-hire.jpg",
        reviewUserDetails: "Heba Naboulsi",
        ...commonContent,
    }
]


export {vehicleTypesTiles, testimonialSlides, popularChoicesTiles}
