import * as actionTypes from './action-types'
import axios from 'axios'
import {TIME_LIMIT_FOR_FETCH} from '../../utils/constants'
import {metadata} from "../../app-config"
import forOwn from 'lodash/forOwn'
import includes from 'lodash/includes'
import *  as APP_CONFIG from '../../config'

export const getAllFeatureArticles = props => {

    return (dispatch, getState) => {

        const timeSinceLastFetch = getState().featureArticles.allArticles.lastFetch
        let isDataStale = Date.now() - timeSinceLastFetch > TIME_LIMIT_FOR_FETCH //
        if (isDataStale) {
            //allow up to 20 feature items in total
            const maxItemLength = 20
            const pageNo = 1
            const FEATURE_CATEGORY_ID = 69

            const featureContent = {
                // FAQS:{
                //   categories:'33',
                //   tags:'34',
                //   data:[],
                //   route:metadata.faqs.route
                // },
                PRODUCTS: {
                    categories: FEATURE_CATEGORY_ID,
                    // tags:'38',
                    data: [],
                    route: metadata.productPage.route
                },
            }

            let allCategoriesArray = []
            let allTagsArray = []
            forOwn(featureContent, (featureTypeData, featureName) => {
                allCategoriesArray.push(featureTypeData['categories'])
                allTagsArray.push(featureTypeData['tags'])
            });
            const allCategories = allCategoriesArray.join()
            const allTags = allTagsArray.join()

            let url = `${APP_CONFIG.CONTENT_URL}?rest_route=/wp/v2/posts&categories=${allCategories}&tags=${allTags}&page=${pageNo}&_embed&per_page=${maxItemLength}`
            if (window.DEBUG) console.time(url)
            dispatch({type: actionTypes.GET_FEATURE_ALL_REQUEST})
            if (window.DEBUG) console.time(url)
            return axios
                .get(url)
                .then(response => {
                    if (window.DEBUG) console.timeEnd(url)
                    //now call actions with data
                    dispatch({
                        type: actionTypes[`GET_FEATURE_ALL_SUCCESS`]
                    })

                    const responseData = response && response.data ? response.data : []
                    // console.log(responseData)

                    //go through each result
                    for (const item of responseData) {
                        //go through each featureContent
                        forOwn(featureContent, (featureTypeData, featureName) => {
                            //match the category and tags and place into relevant array
                            if (
                                includes(item.categories, parseInt(featureTypeData['categories'])) &&
                                includes(item.tags, parseInt(featureTypeData['tags']))
                            ) {
                                featureContent[featureName]['data'].push(item)
                            }
                        })
                    }
                    // console.log(featureContent)
                    //go through each featureContent again to call relevant actions (if required)
                    forOwn(featureContent, (featureTypeData, featureName) => {
                        //if we have data
                        //console.log(featureContent[featureName].data)
                        if (featureContent[featureName].data.length > 0) {
                            let tempResponse = []
                            //go through the data array
                            for (const featureArticle of featureContent[featureName].data) {
                                tempResponse.push({
                                    icon: {
                                        url: featureArticle._embedded['wp:featuredmedia']
                                            ? featureArticle._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url : '',
                                        title: featureArticle.title.rendered
                                    },
                                    title: {
                                        text: featureArticle.title.rendered
                                    },
                                    text: () => {
                                        return (
                                            featureArticle.excerpt.rendered
                                        )
                                    },
                                    linkURL: `${featureContent[featureName]['route'].slice(1)}/${featureArticle.slug}`
                                })
                            }
                            //now call actions with data
                            dispatch({
                                type: actionTypes[`GET_FEATURE_${featureName}_SUCCESS`],
                                payload: tempResponse
                            })
                        }
                    })
                })
                .catch(error => {
                    if (window.DEBUG) console.timeEnd(url)
                    console.log(error)
                    // need to do something here when get 403 error. this means the api end point is forbidden (not auth)
                    dispatch({
                        type: actionTypes.GET_FEATURE_ALL_FAIL,
                        error
                    })
                })
        }
    }
}


export const featureArticleReset = props => {
    return (dispatch, getState) => {
        dispatch({
            type: actionTypes.FEATURE_ARTICLES_RESET,
        })
    }
}
