import * as ROUTE_META from './data.json'
import *  as APP_CONFIG from '../config'
import {popularChoicesTiles, vehicleTypesTiles} from '../pages/home/utils'

const metadata = ROUTE_META.metadata

export const headerLinks = [
    {
        internalLink: true,
        linkDetails: {
            label: metadata.home.linkName,
            url: metadata.home.route,
        }
    },
    // {
    //     internalLink: true,
    //     linkDetails: {
    //         label: metadata.products.linkName,
    //         url: metadata.products.route,
    //     },
    //     subItems: [
    //         {
    //             label: metadata.products.linkName,
    //             url: metadata.products.route,
    //         }
    //     ]
    // },
    {
        internalLink: true,
        linkDetails: {
            label: metadata.contact.linkName,
            url: metadata.contact.route,
        }
    },
    {
        internalLink: true,
        linkDetails: {
            label: metadata.about.linkName,
            url: metadata.about.route,
        }
    },

]


const renderInternalNavLinks = () => {

    //render each internal link that is in the headerLinks array
    let footerNavLinks = []
    for (const navLink of headerLinks) {
        if (navLink.internalLink === true) footerNavLinks.push({value: {...navLink}, type: 'link'})
    }
    return footerNavLinks
}

export const footerContent = {
    columns: [
        {
            content: [
                {
                    type: 'title',
                    value: APP_CONFIG.WEBSITE_NAME
                },
                ...renderInternalNavLinks()
            ]
        },
        {
            content: [
            ]
        },
        {
            content: [
            ]
        },
        {
            content: [
                {
                    type: 'img',
                    specialClass: 'footerLogo',
                    value: {
                        src: `/${APP_CONFIG.FOOTER_IMAGE}`,
                        title: APP_CONFIG.WEBSITE_NAME,
                        alt: APP_CONFIG.WEBSITE_NAME
                    }
                }
            ]
        }
    ]
}
