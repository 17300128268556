import React, {Fragment, useEffect, useState} from 'react'
import LinkTiles from './header-link-tiles'
import HeaderHamburgerMenu from './header-hamburger-menu'
import {NavLink} from 'react-router-dom'
import {metadata} from '../../../app-config'
import config from '../../../config.json'
import {getAllFeatureArticles} from '../../../content/feature-articles/action'
import axios from 'axios'
import StoreManager from '../../../redux/store-manager'
import reducers from '../../../redux/reducers'
import {HeaderContainer} from './header-styles'
import {useCurrentWidth} from '../../../utils/constants'


import {connect} from 'react-redux'
import GlobalSearch from '../GlobalSearch/GlobalSearch'

StoreManager.registerReducers(reducers)

require('./header.scss')

const Header = props => {
    const {
        headerLinks,
        getAllFeatureArticles,
        transparentHeader
    } = props

    // console.log(transparentHeader)

    const [darkMode, setDarkMode] = useState(false)
    //adding headerLinksState to refresh highlighted link clicked
    const [headerLinksState, setHeaderLinksState] = useState(headerLinks)
    const width = useCurrentWidth()

    //start up loading
    useEffect(() => {
        //todo global call? getFeatureMedia ?
        getAllFeatureArticles()

        const payload = {
            "width": window.innerWidth || document.body.clientWidth,
            "height": window.innerHeight || document.body.clientHeight
        }

        axios.post('/api/BI/', payload)
            .then(
                response => {
                    // console.log(response.data)
                }
            )
    }, [])

    //dark mode toggling
    useEffect(() => {
        if (!darkMode) return
        const element = document.getElementById("rfgdz")
        element.classList.toggle("dark-mode")

    }, [darkMode])


    return (
        <Fragment>
            <HeaderContainer
                transparentHeader={transparentHeader}
                width={`${width}px`}
            >
                {/*<HeaderContactDetails*/}
                {/*	primaryMobile={true}*/}
                {/*	email={true}*/}
                {/*/>*/}
                <HeaderHamburgerMenu
                    title={config.WEBSITE_NAME}
                    links={headerLinks}
                    image={config.HEADER_IMAGE}
                    lineColor={transparentHeader ? '#956f04' : '#01508d'}
                    placement="left"
                />
                <div className="headerContent">

                    <div className="logoContainer">
                        <NavLink
                            to={{pathname: metadata.home.route}}
                            title={metadata.home.title}
                            activeClassName="active"
                            onClick={() => {
                                setHeaderLinksState(null)
                                setTimeout(() => {
                                    setHeaderLinksState(headerLinks)
                                }, 200)
                            }}
                        >
                            {transparentHeader && (
                                <img id='logo' src={`/${config.HEADER_IMAGE_DARK}`} title={metadata.home.title}
                                     alt={metadata.home.title}/>
                            )}
                            {!transparentHeader && (
                                <img id='logo' src={`/${config.HEADER_IMAGE}`} title={metadata.home.title}
                                     alt={metadata.home.title}/>
                            )}
                            {/*<img id='logoMobile' src={`/${config.HEADER_MOBILE_IMAGE}`} title={metadata.home.title} alt={metadata.home.title} />*/}
                        </NavLink>
                    </div>

                    <div className="linksContainer">
                        {headerLinksState && (
                            <LinkTiles
                                links={headerLinksState}
                                linksDirection='horizontalContainer'
                                transparentHeader={transparentHeader}
                            />
                        )}
                    </div>

                    {/*<GlobalSearch/>*/}

                </div>
            </HeaderContainer>
        </Fragment>

    )

}

const mapStateToProps = (state, ownProps) => {
    const headerLinks = state.navigation ? state.navigation.header.links : []
    return {
        headerLinks
    }
}

const mapDispatchToProps = {
    getAllFeatureArticles
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
// export default Header
