

const reviewer_1 = {
    reviewerId:1,
    reviewerName:'Google',
    reviewerImg:'assets/img/icons/google_reviews.svg',
    reviewerUrl:'https://www.google.com/search?q=m1+limousines&rlz=1C5CHFA_enAU702AU703&oq=m1+limousines&aqs=chrome.0.35i39j0i22i30j0i390l3j69i61l2j69i60.2711j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6b12bce268d6a423:0x2109ed7d98f5842,1,,,'
}

const reviewer_2= {
    reviewerId:2,
    reviewerName:'Hi Pages',
    reviewerImg:'assets/img/icons/hiPages.svg',
    reviewerUrl:'https://hipages.com.au/connect/harrissparkselectrical'
}

const hiPagesTestimonials = [
    {
        id:1,
        reviewText: "",
        reviewUser: "Richard B",
        reviewUserFrom: "Blacktown, NSW",
        reviewDate: "4 Mar 2020",
        reviewKeywords:"Eletrical Lighting",
        reviewerDetails:{...reviewer_2}
    },
]

const googleTestimonials = [
    {
        id:1,
        reviewText: "",
        reviewUser: "Richard B",
        reviewUserFrom: "Blacktown, NSW",
        reviewDate: "4 Mar 2020",
        reviewKeywords:"Eletrical Lighting",
        reviewerDetails:{...reviewer_2}
    },
]

export {reviewer_1, googleTestimonials, hiPagesTestimonials}

