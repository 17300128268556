import React from 'react'
import TweenFunctions from 'tween-functions'
import detectPassiveEvents from 'detect-passive-events'
import { BackToTopSection } from './styles'
import './backToTop.scss';


const defaultProps = {
    ContainerClassName: 'ScrollUpButton__Container',
    StopPosition: 0,
    ShowAtPostion: 150,
    EasingType: 'easeOutCubic',
    AnimationDuration: 500,
    TransitionClassName: 'ScrollUpButton__Toggled',
    style: {},
    ToggledStyle: {}
}

class BackToTop extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ToggleScrollUp: '' }
        this.Animation = {
            StartPosition: 0,
            CurrentAnimationTime: 0,
            StartTime: null,
            AnimationFrame: null
        }
        this.HandleScroll = this.HandleScroll.bind(this)
        this.StopScrollingFrame = this.StopScrollingFrame.bind(this)
        this.ScrollingFrame = this.ScrollingFrame.bind(this)
        this.HandleClick = this.HandleClick.bind(this)
    }
    HandleScroll() {
        //window.pageYOffset = current scroll position
        //ShowAtPostion = position at which we want the button to show.
        if (window.pageYOffset > this.props.ShowAtPostion) {
            //styles.Toggled = the class name we want applied to transition the button in.
            this.setState({ ToggleScrollUp: this.props.TransitionClassName })
        } else {
            //remove the class name
            this.setState({ ToggleScrollUp: '' })
        }
    }
    HandleClick() {
        this.StopScrollingFrame() //Stoping all AnimationFrames
        this.Animation.StartPosition = window.pageYOffset //current scroll position
        this.Animation.CurrentAnimationTime = 0
        this.Animation.StartTime = null
        //Start the scrolling animation.
        this.Animation.AnimationFrame = window.requestAnimationFrame(
            this.ScrollingFrame
        )
    }
    ScrollingFrame() {
        let timestamp = Math.floor(Date.now())
        //If StartTime has not been assigned a value, assign it the start timestamp.
        if (!this.Animation.StartTime) {
            this.Animation.StartTime = timestamp
        }

        //set CurrentAnimationTime every iteration of ScrollingFrame()
        this.Animation.CurrentAnimationTime =
            timestamp - this.Animation.StartTime
        //if we hit the StopPosition, StopScrollingFrame()
        if (window.pageYOffset <= this.props.StopPosition) {
            this.StopScrollingFrame()
        } else {
            //Otherwise continue ScrollingFrame to the StopPosition.
            //Does not support horizontal ScrollingFrame.
            //Let TweenFunctions handle the math to give us a new position based on AnimationDuration and EasingType type
            let YPos = TweenFunctions[this.props.EasingType](
                this.Animation.CurrentAnimationTime,
                this.Animation.StartPosition,
                this.props.StopPosition,
                this.props.AnimationDuration
            )
            window.scrollTo(0, YPos)
            //Request another frame to be painted
            this.Animation.AnimationFrame = window.requestAnimationFrame(
                this.ScrollingFrame
            )
        }
    }
    StopScrollingFrame() {
        //Stop the Animation Frames.
        window.cancelAnimationFrame(this.Animation.AnimationFrame)
    }
    componentDidMount() {
        this.HandleScroll() // run HandleScroll() at mount incase we are already scrolled down
        window.addEventListener('scroll', this.HandleScroll)
        window.addEventListener(
            'wheel',
            this.StopScrollingFrame,
            detectPassiveEvents.hasSupport ? { passive: true } : false
        ) //Stop animation if user mouse wheels during animation.
        window.addEventListener(
            'touchstart',
            this.StopScrollingFrame,
            detectPassiveEvents.hasSupport ? { passive: true } : false
        ) //Stop animation if user touches the screen during animation.
    }
    componentWillUnmount() {
        //Remove all events, since component is no longer mounted.
        window.removeEventListener('scroll', this.HandleScroll)
        window.removeEventListener('wheel', this.StopScrollingFrame, false)
        window.removeEventListener('touchstart', this.StopScrollingFrame, false)
    }
    render() {
        const styles = {
            MainStyle: {
                backgroundColor: 'rgba(50, 50, 50, 0.5)',
                height: 50,
                position: 'fixed',
                bottom: 20,
                width: 50,
                WebkitTransition: 'all 0.5s ease-in-out',
                transition: 'all 0.5s ease-in-out',
                transitionProperty: 'opacity, right',
                cursor: 'pointer',
                opacity: 0,
                right: -50,
                zIndex: 1000
            },
            SvgStyle: {
                display: 'inline-block',
                width: '100%',
                height: '100%',
                strokeWidth: 0,
                stroke: 'white',
                fill: 'white',
                paddingLeft: 9
            },
            ToggledStyle: {
                opacity: 0.8,
                right: 20
            }
        }
        if (this.props.children) {
            const childrenWithProps = React.Children.map(
                this.props.children,
                child =>
                    React.cloneElement(child, {
                        className: this.className
                    })
            )
            return (
                <div
                    style={Object.assign(
                        {},

                        this.state.ToggleScrollUp && this.props.ToggledStyle
                    )}
                    className={`${this.props.ContainerClassName} ${this.state.ToggleScrollUp} circleArrow`}
                    onClick={this.HandleClick}
                >
                    {childrenWithProps}
                </div>
            )
        } else {
            return (
                <aside
                    className={
                        this.props.ContainerClassName +
                        ' ' +
                        this.state.ToggleScrollUp
                    }
                    style={Object.assign(
                        {},
                        styles.MainStyle,
                        this.props.style,
                        this.state.ToggleScrollUp && styles.ToggledStyle,
                        this.state.ToggleScrollUp && this.props.ToggledStyle
                    )}
                    onClick={this.HandleClick}
                >
                    <svg
                        viewBox="0 0 32 32"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        xmlSpace="preserve"
                        style={styles.SvgStyle}
                    >
                        <path d="M19.196 23.429q0 0.232-0.179 0.411l-0.893 0.893q-0.179 0.179-0.411 0.179t-0.411-0.179l-7.018-7.018-7.018 7.018q-0.179 0.179-0.411 0.179t-0.411-0.179l-0.893-0.893q-0.179-0.179-0.179-0.411t0.179-0.411l8.321-8.321q0.179-0.179 0.411-0.179t0.411 0.179l8.321 8.321q0.179 0.179 0.179 0.411zM19.196 16.571q0 0.232-0.179 0.411l-0.893 0.893q-0.179 0.179-0.411 0.179t-0.411-0.179l-7.018-7.018-7.018 7.018q-0.179 0.179-0.411 0.179t-0.411-0.179l-0.893-0.893q-0.179-0.179-0.179-0.411t0.179-0.411l8.321-8.321q0.179-0.179 0.411-0.179t0.411 0.179l8.321 8.321q0.179 0.179 0.179 0.411z" />
                    </svg>
                </aside>
            )
        }
    }
}
export default BackToTop

export const CircleArrow = props => {
    const styles = {
        MainStyle: {
            backgroundColor: 'rgb(255, 255, 255)',
            borderRadius: '50%',
            border: '3px solid #f26b29',
            height: 40,
            position: 'fixed',
            bottom: 60,
            width: 40,
            padding: '5px',
            WebkitTransition: 'all 0.5s ease-in-out',
            transition: 'all 0.5s ease-in-out',
            transitionProperty: 'opacity, right',
            cursor: 'pointer',
            //opacity: 0,
            right: -75
        },
        ToggledStyle: {
            //opacity: 0.6,
            right: 20
        }
    }
    return (
        <BackToTopSection>
            <BackToTop
                {...props}
                {...defaultProps}
                style={Object.assign(
                    {},
                    styles.MainStyle,
                    props.style,
                    defaultProps.style
                )}
                ToggledStyle={Object.assign(
                    {},
                    styles.ToggledStyle,
                    props.ToggledStyle,
                    defaultProps.ToggledStyle
                )}
            >
                <div className="arrow">
                    <svg viewBox="0 0 443.9 168.3" >
                        <defs>
                        </defs>
                        <g id="ho8ZEq.tif_1_">
                            <g>
                                <g>
                                    <path  className="upArrow" d="M443.9,144.3c-1.7,1.4-3,2.5-4.4,3.6c-8.6,6.5-17.3,12.9-25.9,19.4c-1.8,1.4-3.1,1.3-5,0.1
                                        c-55.1-36.3-110.3-72.5-165.4-108.7c-6.3-4.2-12.7-8.2-18.9-12.5c-1.8-1.2-3.1-1-4.8,0c-19.8,12.8-39.6,25.6-59.4,38.3
                                        c-30.5,19.6-60.9,39.2-91.4,58.9c-11.5,7.4-23,14.8-34.3,22.3c-2.3,1.5-3.8,1.4-6-0.2c-8.5-6.5-17.2-12.8-25.8-19.2
                                        c-0.8-0.6-1.5-1.2-2.6-2.1c0.9-0.7,1.6-1.4,2.3-1.9C41.1,117.2,79.8,92,118.6,66.7C152.1,44.9,185.7,23,219.2,1
                                        c1.9-1.2,3.2-1.5,5.2-0.1c35.9,23.5,71.8,46.9,107.8,70.4c35.7,23.3,71.4,46.5,107.1,69.8C440.8,142,442.2,143.1,443.9,144.3z"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

            </BackToTop>
        </BackToTopSection>
    )
}
