import axios from 'axios'
import *  as APP_CONFIG from '../config'

const forbiddenStatusCodes = [401, 403, 405, 406]

export const configureInterceptors = () => {
    /**
     * @name constructor
     * @desc intercept the outgoing http request and append the token (for Canon STS)
     * @desc calling them injectors cause i keep forgetting there interceptors
     * @param {Object} request - the request properties
     */


    axios.interceptors.request.use(
        (request) => {

            // console.log(window.location.hostname)

            if (window.location.hostname === 'localhost') {
                // add new environment
                // console.log(window.location.hostname)
                // console.log(request.url)
                const bla = request.url.split('/')
                // console.log(bla[1])
                if (`/${bla[1]}/` === APP_CONFIG.CONTENT_URL) {
                    request.url = `http://localhost/${request.url}`
                    // console.log(request.url)
                }
                //request.url = request.url.replace()
            }

            return request
        },
        (error) => {
            return Promise.reject(error)
        }
    )
    // ---------------------------------------------
    //   HTTP INTERCEPTOR FOR AXIOS - RESPONSE
    // ---------------------------------------------
    axios.interceptors.response.use(
        (response) => {
            // console.log(response?.status)
            // if (response?.status === 400) {
            //     console.log('foo')
            // }
            return response
        },
        (error) => {
            // console.log(error?.response)
            let returnedError = error
            // if (error?.response?.status === 400) {
            //     console.log('bar')
            // }
            if (error?.response?.status === 500) {

                const SERVER_ERROR = {
                    response: {
                        data: {
                            data: [
                                'Server error, please try again later.'
                            ],
                        },
                    },
                }
                returnedError = SERVER_ERROR
            }
            // console.error(error.response)
            //todo actually fix this
            // if (error.response && forbiddenStatusCodes.includes(error.response.status))
            // 	history.push(metadata.accesserror.route)
            return Promise.reject(returnedError)
        }
    )
}


