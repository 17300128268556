import React, {Fragment, Suspense} from 'react'
import Loading from './components/common/loading'
import {Helmet} from "react-helmet";
import {metadata} from "./app-config"
import Header from './components/common/header/header'

const LazyHOC = props => {
    return (

        <Fragment>
            {!props.noHeader && (
                <Header
                    transparentHeader={props.transparentHeader || false}
                />
            )}
            {props.children}
        </Fragment>


    );
}

const HelmetHOC = props => {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description}/>
        </Helmet>
    )
}

const LazyLanding = React.lazy(() => import('./pages/home'));
const LazyGalleryPage = React.lazy(() => import('./pages/gallery'));
const LazyContact = React.lazy(() => import('./pages/contact'));
const LazyAbout = React.lazy(() => import('./pages/about'));
const LazyFaqsPage = React.lazy(() => import('./pages/faqs'));
const LazyFaqPage = React.lazy(() => import('./pages/faqs/faq'));
const LazyBlogsPage = React.lazy(() => import('./pages/blogs'));
const LazyBlogPage = React.lazy(() => import('./pages/blogs/blog'));
const LazyProductsPage = React.lazy(() => import('./pages/products'));
const LazyProductPage = React.lazy(() => import('./pages/products/product'));
const LazyTestimonialsPage = React.lazy(() => import('./pages/testimonials'));
const LazySearchPage = React.lazy(() => import('./pages/search'));
const LazyPackagesPage = React.lazy(() => import('./pages/packages'));
const LazyPackagePage = React.lazy(() => import('./pages/packages/package'));
const LazySpecialOffersPage = React.lazy(() => import('./pages/special-offers'));
const LazySpecialOfferPage = React.lazy(() => import('./pages/special-offers/special-offer'));

function Landing() {
    return (
        <Suspense fallback={<Loading message={metadata.home.description}/>}>
            {HelmetHOC(metadata.home)}
            <LazyHOC><LazyLanding/></LazyHOC>
        </Suspense>
    )
}


function GalleryPage() {
    return (
        <Suspense fallback={<Loading message={metadata.gallery.description}/>}>
            {HelmetHOC(metadata.gallery)}
            <LazyHOC><LazyGalleryPage/></LazyHOC>
        </Suspense>
    )
}

function Contact() {
    return (
        <Suspense fallback={<Loading message={metadata.contact.description}/>}>
            {HelmetHOC(metadata.contact)}
            <LazyHOC><LazyContact/></LazyHOC>
        </Suspense>
    )
}

function About() {
    return (
        <Suspense fallback={<Loading message={metadata.about.description}/>}>
            {HelmetHOC(metadata.about)}
            <LazyHOC><LazyAbout/></LazyHOC>
        </Suspense>
    )
}

function FaqsPage() {
    return (
        <Suspense fallback={<Loading message={metadata.faqs.description}/>}>
            {HelmetHOC(metadata.faqs)}
            <LazyHOC><LazyFaqsPage/></LazyHOC>
        </Suspense>
    )
}

function FaqPage() {
    return (
        <Suspense fallback={<Loading message={metadata.faq.description}/>}>
            {HelmetHOC(metadata.faq)}
            <LazyHOC><LazyFaqPage/></LazyHOC>
        </Suspense>
    )
}

function BlogsPage() {
    return (
        <Suspense fallback={<Loading message={metadata.blogs.description}/>}>
            {HelmetHOC(metadata.blogs)}
            <LazyHOC><LazyBlogsPage/></LazyHOC>
        </Suspense>
    )
}

function BlogPage() {
    return (
        <Suspense fallback={<Loading message={metadata.blog.description}/>}>
            {HelmetHOC(metadata.blog)}
            <LazyHOC><LazyBlogPage/></LazyHOC>
        </Suspense>
    )
}

function ProductsPage() {
    return (
        <Suspense fallback={<Loading message={metadata.products.description}/>}>
            {HelmetHOC(metadata.products)}
            <LazyHOC><LazyProductsPage/></LazyHOC>
        </Suspense>
    )
}

function ProductPage() {
    return (
        <Suspense fallback={<Loading message={metadata.productPage.description}/>}>
            {HelmetHOC(metadata.productPage)}
            <LazyHOC><LazyProductPage/></LazyHOC>
        </Suspense>
    )
}

function TestimonialsPage() {
    return (
        <Suspense fallback={<Loading message={metadata.testimonials.description}/>}>
            {HelmetHOC(metadata.testimonials)}
            <LazyHOC><LazyTestimonialsPage/></LazyHOC>
        </Suspense>
    )
}

function SearchPage() {
    return (
        <Suspense fallback={<Loading message={metadata.search.description}/>}>
            {HelmetHOC(metadata.search)}
            <LazyHOC><LazySearchPage/></LazyHOC>
        </Suspense>
    )
}

function PackagesPage() {
    return (
        <Suspense fallback={<Loading message={metadata.packages.description}/>}>
            {HelmetHOC(metadata.packages)}
            <LazyHOC><LazyPackagesPage/></LazyHOC>
        </Suspense>
    )
}

function PackagePage() {
    return (
        <Suspense fallback={<Loading message={metadata.package.description}/>}>
            {HelmetHOC(metadata.package)}
            <LazyHOC><LazyPackagePage/></LazyHOC>
        </Suspense>
    )
}

function SpecialOffersPage() {
    return (
        <Suspense fallback={<Loading message={metadata.specialOffers.description}/>}>
            {HelmetHOC(metadata.specialOffers)}
            <LazyHOC><LazySpecialOffersPage/></LazyHOC>
        </Suspense>
    )
}

function SpecialOfferPage() {
    return (
        <Suspense fallback={<Loading message={metadata.specialOffer.description}/>}>
            {HelmetHOC(metadata.specialOffer)}
            <LazyHOC><LazySpecialOfferPage/></LazyHOC>
        </Suspense>
    )
}


export {
    Landing,
    Contact,
    About,
    SearchPage,
    GalleryPage,
    TestimonialsPage,
    BlogsPage,
    BlogPage,
    FaqsPage,
    FaqPage,
    ProductsPage,
    ProductPage,
    PackagesPage,
    PackagePage,
    SpecialOffersPage,
    SpecialOfferPage,
}
