import React, { useEffect } from 'react'
import { gsap } from "gsap"
import anime from 'animejs'
require ('./big-logo-loader.scss')

const BigLogoLoader = props => {
    // constructor(props) {
    //     super(props)
    //     this.state = {}
    //     this.tl = new gsap.timeline({ repeat: -1 })
    //     this.tl2 = new gsap.timeline({ repeat: -1 })
    // }

    useEffect(()=>{

      const tl = new gsap.timeline({ repeat: -1 })
      const tl2 = new gsap.timeline({ repeat: -1 })

      const selector1 = '.large-select-1'
      const selector2 = '.large-select-0, .large-select-1'

    //pulsate animation
    //   tl.set(selector1, { autoAlpha: 0.7 })
    //     .to(selector1, 0.5, {
    //       scale: 0.8,
    //       transformOrigin: '50% 50%'
    //     })
    //     .to(selector1, 0.5, { scale: 1,  transformOrigin: '50% 50%' })

      //page turn
      // tl.from(selector1, 1, { scaleX: 0, transformOrigin: "left" })
      // tl.to(selector1, 1, { scaleX: 0, transformOrigin: "right" }, "reveal")
      // tl.from(selector1, 1, { opacity: 0 }, "reveal")

        tl.to(selector1, 1, {drawSVG:true})

      const svgText = anime.timeline({

        endDelay: 0,
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true
      })

        .add({ targets: ['.large-select-1',],
          //points: '80.1,0 43.7,48.2 34.3,48.2 31.9,24.9 0,62.7 30.8,17.3 39.3,17.3 42.3,42.3 ',
          baseFrequency: 3,

        })
        .add({ targets: '.large-select-1',  fill: '#015da7' }, 0)
        .add({ targets: '.large-select-0',  fill: '#003a68' }, 0)

    },[])



    //render() {
        return (
          <div className="loaderContainer" >
            <div className="small-loading-icon-container" >
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 578.5 250.68">
                    <defs>
                        {/*<style>.cls-1{fill:#2a2a2a;}.cls-2{fill:#015da7;}.cls-3{fill:#00518f;}</style>*/}
                    </defs>
                    <path className="large-select-1"
                          d="M37.16,244.22c-.24,5.89-.48,7.05-2,8.43-1.86,1.7-4.87,2.16-14.71,2.16-12.56,0-15.91-.46-18-2.44C.67,250.67,0,247.26,0,239.84c0-8.53.76-12.17,3-13.87,1.48-1.15,3.2-1.52,7.69-1.85,2.1-.09,6.4-.22,9.17-.22a62.3,62.3,0,0,1,11.8.78c2.39.55,4.3,2.16,4.73,4.1.33,1.24.38,1.7.43,5.39h-4v-1.06c-.05-3-.48-3.92-2.2-4.61s-5.25-.92-11-.92c-8.41,0-12.28.42-13.56,1.38s-1.77,3.92-1.77,9.27c0,2.53.28,7.46.48,9,.38,2.35,1.43,3.32,4.1,3.55,2.1.23,6.4.37,10.32.37,2.72,0,8.41-.23,9.65-.37,3.44-.41,4.15-1.29,4.15-5.16,0-.19,0-.78,0-1.38Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M77.51,226.38c1.29,1.2,1.86,2.68,2.24,5.76.2,1.66.34,4.8.34,7.33,0,7.56-.67,11-2.44,12.86s-5.35,2.48-15.14,2.48c-4.77,0-11.84-.18-13.66-.36-3.2-.28-4.77-.88-6.2-2.35-1.92-1.89-2.39-4.57-2.39-12.86S41,227.72,43.36,226c1.58-1.15,3.15-1.57,7.21-1.8,2-.14,7.5-.27,10.56-.27C72,223.9,75.46,224.4,77.51,226.38ZM46.23,229.1c-1.15,1-1.63,3.92-1.63,9.82,0,6.82.34,9.49,1.34,10.55.81.88,2.05,1.2,5.35,1.38,1.77.14,7.16.28,9.46.28a81.69,81.69,0,0,0,11.22-.6,3.61,3.61,0,0,0,3.25-3.13,56.7,56.7,0,0,0,.52-7.84,59.74,59.74,0,0,0-.47-8.52c-.29-1.75-1.44-2.68-3.59-3-2.43-.37-4.92-.46-13-.46C50.72,227.58,47.47,228,46.23,229.1Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M91.31,224l27.84,26.95h1V224h4.26v30.73h-7.22L89.44,227.81h-1v26.91H84.24V224Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M132.42,245.14v.64c.1,3.51.39,4.2,1.87,4.84,1.24.51,4.87.74,11.6.74a60,60,0,0,0,10.22-.69c2.06-.42,3.06-1.94,3.06-4.56s-.57-3.83-2.15-4.38-1.58-.55-17.29-.55c-8.4,0-11.51-2.31-11.51-8.58,0-2.76.81-4.79,2.39-6s3.15-1.75,6.59-2.17c2.39-.28,6.78-.5,10-.5,7.16,0,10.7.64,12.89,2.34,1.82,1.39,2.35,2.91,2.39,6.87h-4.25v-.46c0-2.26-.24-3-1-3.59-1.53-1.2-4.44-1.66-10.32-1.66-8.16,0-11.89.55-13.37,1.93a4.5,4.5,0,0,0-1,3.32c0,2.72.52,3.64,2.53,4.24,1.53.51,4,.69,8.36.74l10.36,0c4.54,0,6.69.74,8.22,2.72a8.52,8.52,0,0,1,1.48,5.39c0,3.78-1.1,6.09-3.54,7.38s-6.92,1.65-16.81,1.65c-7,0-9.79-.32-11.8-1.42-2.34-1.25-3.1-2.95-3.1-6.87,0-.09,0-.83,0-1.38Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1" d="M201.1,224v3.59H185.19v27.14h-4.25V227.58h-16V224Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M204,224h19.87c8,.09,8.07.09,10.26.36a6.88,6.88,0,0,1,5.5,3.42,11.48,11.48,0,0,1,1,5.48c0,5.67-1,7.28-5.25,8.11a5.49,5.49,0,0,1,3.24,1.57c1.25,1.24,1.44,2.16,1.44,7.42v4.37h-4.25v-2.95c0-4,0-4-.2-5.2a3.62,3.62,0,0,0-3.39-3.41c-1.48-.23-1.48-.23-8.35-.28H208.26v11.84H204Zm19.87,15.3c6.87,0,7.88,0,9.31-.42,2.34-.6,3.1-2,3.1-5.67,0-2.16-.57-3.87-1.52-4.51-1.29-.92-3.25-1.11-10.89-1.11H208.26v11.71Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M248.52,224V239.1c0,8.76,0,9.13.9,10.32s4,1.71,12.56,1.71c10.37,0,13.47-.37,14.62-1.66.91-1,.95-1.52,1-10.37V224h4.25V239.1a76.09,76.09,0,0,1-.24,8.48c-.33,3.73-2.29,5.94-5.78,6.59a100.68,100.68,0,0,1-12.75.64,86.39,86.39,0,0,1-13.08-.64,6.73,6.73,0,0,1-5.4-6c-.24-1.89-.29-2.48-.33-9V224Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M322.87,244.22c-.24,5.89-.47,7.05-2,8.43-1.86,1.7-4.87,2.16-14.71,2.16-12.56,0-15.9-.46-18-2.44-1.82-1.7-2.48-5.11-2.48-12.53,0-8.53.76-12.17,3-13.87,1.48-1.15,3.2-1.52,7.69-1.85,2.1-.09,6.4-.22,9.17-.22a62.24,62.24,0,0,1,11.79.78c2.39.55,4.3,2.16,4.73,4.1.34,1.24.38,1.7.43,5.39h-4v-1.06c0-3-.48-3.92-2.2-4.61s-5.25-.92-11-.92c-8.41,0-12.28.42-13.57,1.38s-1.77,3.92-1.77,9.27c0,2.53.29,7.46.48,9,.38,2.35,1.43,3.32,4.11,3.55,2.1.23,6.4.37,10.32.37,2.72,0,8.4-.23,9.64-.37,3.44-.41,4.16-1.29,4.16-5.16,0-.19,0-.78,0-1.38Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1" d="M360.74,224v3.59h-15.9v27.14h-4.25V227.58h-16V224Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M413.42,239.15c-.05,5.9-.1,7.51-.48,9.26-.67,3.13-1.86,4.52-4.78,5.44-1.91.6-6.49.92-13.23.92a116.4,116.4,0,0,1-13.7-.6c-3.06-.46-5.21-2.21-5.88-4.84a44,44,0,0,1-.91-10,41.7,41.7,0,0,1,1-10c.76-2.85,2.77-4.42,6.21-4.93a80.44,80.44,0,0,1,11.51-.59c4.39,0,11,.27,13,.55a7.77,7.77,0,0,1,6.16,4c.67,1.38.86,2.53.91,5.21h-4.35c-.05-2.68-.33-3.83-1.05-4.52s-2-1.06-5.4-1.29c-1.76-.14-5.87-.23-8.5-.23-8.93,0-12.22.37-13.51,1.57s-1.58,3.45-1.58,9.63a51.4,51.4,0,0,0,.57,9.08c.86,2.95,2.34,3.22,17.24,3.22,7.46,0,10.13-.41,11.37-1.66.86-.87,1.1-2.21,1.2-6.68H392.55v-3.59Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M417.38,224h19.86c8,.09,8.07.09,10.27.36a6.84,6.84,0,0,1,5.49,3.42,11.34,11.34,0,0,1,1,5.48c0,5.67-1.05,7.28-5.26,8.11a5.49,5.49,0,0,1,3.25,1.57c1.24,1.24,1.44,2.16,1.44,7.42v4.37h-4.26v-2.95c0-4,0-4-.19-5.2a3.63,3.63,0,0,0-3.39-3.41c-1.48-.23-1.48-.23-8.36-.28H421.63v11.84h-4.25Zm19.86,15.3c6.88,0,7.88,0,9.32-.42,2.34-.6,3.1-2,3.1-5.67,0-2.16-.57-3.87-1.53-4.51-1.29-.92-3.24-1.11-10.89-1.11H421.63v11.71Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M494.6,226.38c1.29,1.2,1.86,2.68,2.24,5.76.19,1.66.34,4.8.34,7.33,0,7.56-.67,11-2.44,12.86s-5.35,2.48-15.14,2.48c-4.77,0-11.84-.18-13.66-.36-3.2-.28-4.77-.88-6.21-2.35-1.91-1.89-2.38-4.57-2.38-12.86s.76-11.52,3.1-13.27c1.58-1.15,3.15-1.57,7.21-1.8,2-.14,7.5-.27,10.56-.27C489.11,223.9,492.54,224.4,494.6,226.38Zm-31.28,2.72c-1.15,1-1.63,3.92-1.63,9.82,0,6.82.34,9.49,1.34,10.55.81.88,2.05,1.2,5.35,1.38,1.77.14,7.16.28,9.45.28a81.75,81.75,0,0,0,11.23-.6,3.61,3.61,0,0,0,3.25-3.13,56.7,56.7,0,0,0,.52-7.84,57.93,57.93,0,0,0-.48-8.52c-.28-1.75-1.43-2.68-3.58-3-2.43-.37-4.92-.46-13-.46C467.81,227.58,464.56,228,463.32,229.1Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M505.24,224V239.1c0,8.76.05,9.13.91,10.32s4,1.71,12.56,1.71c10.37,0,13.47-.37,14.62-1.66.9-1,.95-1.52,1-10.37V224h4.25V239.1a76.09,76.09,0,0,1-.24,8.48c-.33,3.73-2.29,5.94-5.78,6.59a100.68,100.68,0,0,1-12.75.64,86.39,86.39,0,0,1-13.08-.64,6.72,6.72,0,0,1-5.4-6c-.24-1.89-.29-2.48-.34-9V224Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-1"
                          d="M543.07,224h20.15c10.75.23,10.75.23,12.71,1.66s2.57,3.64,2.57,7.83-.66,6.36-2.38,7.74-3.06,1.8-7,1.89c-1,0-1,0-5.92,0h-15.9v11.56h-4.25Zm20.15,15.57c7.74,0,8.36-.09,9.65-1.1,1-.79,1.29-1.94,1.29-4.84,0-3.18-.24-4.2-1.2-4.89-1.29-.92-1.76-1-9.74-1.15h-15.9v12Z"
                          transform="translate(0 -4.14)"/>
                    <polygon className="large-select-1" points="199.28 0 87.57 123.54 199.28 123.54 199.28 0"/>
                    <path className="large-select-1"
                          d="M335.51,126.38c-42.94,0-74.55-26.86-74.55-62.31,0-35.81,31.61-59.93,74.55-59.93h41.93L348.53,39.77h-13c-19.55,0-32.16,6.21-32.16,24.3,0,18.27,12.61,27.23,32.16,27.23h13l28.91,35.08Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-2" d="M20.2,157.79V197H8V157.79Z" transform="translate(0 -4.14)"/>
                    <path className="large-select-2" d="M39,157.79l38.79,27.55V157.79H90.07V197H77.79L39,169.5V197H26.75V157.79Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-2"
                          d="M108.22,157.79,147,185.34V157.79h12.29V197H147L108.22,169.5V197H96V157.79Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-2"
                          d="M214.68,157.79a15.83,15.83,0,0,1,10.22,3.35,9.94,9.94,0,0,1,4.19,8.11v16.32a10,10,0,0,1-4.19,8.11A15.88,15.88,0,0,1,214.68,197H179.6a15.94,15.94,0,0,1-10.22-3.35,10.08,10.08,0,0,1-4.2-8.11V169.25a10.06,10.06,0,0,1,4.2-8.11,15.88,15.88,0,0,1,10.22-3.35Zm-35.57,10.08a3.88,3.88,0,0,0-1.68,3.24v12.6a4.12,4.12,0,0,0,1.68,3.27,6.54,6.54,0,0,0,4.09,1.31h27.88a6.88,6.88,0,0,0,4.09-1.34,4.11,4.11,0,0,0,1.67-3.24v-12.6a4,4,0,0,0-1.67-3.27,5.8,5.8,0,0,0-4.09-1.32H183.2A6.12,6.12,0,0,0,179.11,167.87Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-2" d="M260.28,197l-27.22-39.24h14.18l20.18,29.5,20.11-29.5h14.19L274.49,197Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-2"
                          d="M337.82,157.79,366.31,197h-13.2l-6.19-8.59H316.56L310.3,197H297.13l28.5-39.24Zm-15.24,22.34h18.28l-9.1-12.6Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-2" d="M420.79,157.79v8.73H397.53V197H385.28V166.52H362.06v-8.73Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-2" d="M438,157.79V197H425.7V157.79Z" transform="translate(0 -4.14)"/>
                    <path className="large-select-2" d="M469.76,197l-27.22-39.24h14.19l20.17,29.5L497,157.79H511.2L484,197Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-2"
                          d="M570.56,157.79v8.73H527.38v6.42h37.41v8.59H527.38v6.76h43.18V197H515.13V157.79Z"
                          transform="translate(0 -4.14)"/>
                    <path className="large-select-3" d="M213.09,126.38V4.14H255.3V126.38Z" transform="translate(0 -4.14)"/>
                    <path className="large-select-3"
                          d="M426.86,126.38c-42.95,0-74.56-26.86-74.56-62.31,0-35.81,31.61-59.93,74.56-59.93h64.07l-.74,35.63H426.86c-18.28,0-32.35,11.14-32.35,26.13,0,15.53,13.34,25.4,32.35,25.4h21.37V78.87H411.77l.46-30.51h78v78Z"
                          transform="translate(0 -4.14)"/>
                </svg>
            </div>
          </div>
        )
    //}
}

export default BigLogoLoader
